import { Api } from './api';
import { IContact } from '../types/types';
export const contacts = <T>(
  isPaginated: boolean,
  page: number,
  limit: number,
  sort?: string
): Promise<T> => {
  return Api().get(
    `/contacts?isPaginated=${isPaginated}&page=${page}&limit=${limit}${
      sort ? `&sort=` + sort : ''
    }`
  );
};
export const details = <T>(id: number): Promise<T> => {
  return Api().get(`/contacts/${id}`);
};
export const addContact = <T>(payload: IContact): Promise<T> => {
  return Api().post(`/contacts`, payload);
};

export const updateContact = <T>(id: number, payload: IContact): Promise<T> => {
  return Api().put(`/contacts/${id}`, payload);
};

export const assignContactToGroup = <T>(
  contactId: number,
  groupId: number[]
): Promise<T> => {
  return Api().put(
    `/contacts/assign/${contactId}/groups?groupId=${groupId.join(',')}`
  );
};

export const removeContact = <T>(id: number): Promise<T> => {
  return Api().delete(`/contacts/${id}`);
};

export const removeAllContact = <T>(ids: string): Promise<T> => {
  return Api().delete(`/contacts?contactIds=${ids}`);
};

export const fileDownload = <T>(payload: {
  fileName: string;
  ext: string;
}): Promise<T> => {
  return Api().get(
    `/templates/download?file=${payload.fileName}&ext=${payload.ext}`,
    {
      responseType: 'blob',
    }
  );
};

export const bulkContactDownload = <T>(
  fileName: string,
  fields: ReadonlyArray<string>
): Promise<T> => {
  return Api().get(
    `/contacts/bulk/download?fileName=${fileName}&fields=${fields.join(',')}`,
    { responseType: 'blob' }
  );
};

export const bulkContactUpload = <T>(payload: File): Promise<T> => {
  return Api().post(`/contacts/bulk/upload`, payload);
};

export const bulkContactProcess = <T>(payload: File): Promise<T> => {
  return Api().post(`/contacts/bulk/list`, payload);
};

export const searchContacts = <T>(
  query: string,
  page: number,
  limit: number,
  isPaginated = true,
  sort = 'name,ASC'
): Promise<T> => {
  return Api().get(
    `/contacts/search/list?isPaginated=${isPaginated}&query=${query}&page=${page}&limit=${limit}&sort=${sort}`
  );
};
